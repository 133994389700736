import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

class LangPicker extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		return (
			
			<div className={`lang-picker`}>
				
				{this.props.langs.map((langCode, langIndex) => {
					return (
						<img
							key={`lang-${langIndex}`}
							className={`lang ${i18next.language == langCode ? 'lang-active' : ''} lang-${langCode} clickable`}
							onClick={() => { 
								if (this.props.langOnCLick) {
									this.props.langOnCLick(langCode)
								}
							}}
						/>
					)
				})}
				
			</div>
			
		)
		
	}
	
}

LangPicker.propTypes = {
	preloader: PropTypes.object,
	langs: PropTypes.array,
	langOnCLick: PropTypes.func,
}

LangPicker.defaultProps = {
	langs: [
		'ru-RU',
		'en-GB',
	]
}

export default LangPicker