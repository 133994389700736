export default class Claim {
    
    static serviceTypeAliasGrant = 'grant'
    static serviceTypeAliasAudit = 'audit'
    static serviceTypeAliasDev = 'dev'
    
    constructor (data = {}) {
        this.serviceTypeAlias = data.serviceTypeAlias
        this.contactName = data.contactName
        this.phone = data.phone
        this.email = data.email
    }
    
}