import './Landing.css'
import './Landing_SM-.css'

import React from 'react'
import PropTypes from 'prop-types'
import i18next, { t } from 'i18next'
import LandingDevSample from './LandingDevSample'
import LandingContactsItem from './LandingContactsItem'
import ClaimForm from './ClaimForm'
import ClaimModel from '../models/ClaimModel'
import Config from '../helpers/Config'
import Utils from '../helpers/Utils'
import LangPicker from './LangPicker'
import Logger from '../helpers/Logger'

class Landing extends React.Component {

	constructor(props) {
		
		super(props)
		
		this.state = {
			
			useDirectorsPhoto: 0,
			
			useLangPicker: 1,
			
			directorDetailsShown: 0,
			
			// lang: 'ru-RU',
			// lang: 'en-GB',
			lang: i18next.language,
			
		}
		
		this.claimFormRef = React.createRef()
		
	}
	
	setLang = (langCode) => {
		
		i18next.changeLanguage(langCode)
		
		this.setState({
			lang: langCode,
		})
		
	}
	
	componentDidMount() {}

	render() {
		
		const logName = 'Landing.render'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		Logger.log(this.state.lang, 'this.state.lang', logAllow)
		
		let grantsItems = [
			"Подготовка и сопровождение документации в грантах",
			"Анализ и консультации по проекту от момента подачи до отчетности",
			"Составление подробного бизнес-плана",
			"Разработка полного технического предложения\n(если это требуется в рамках вашей идеи)",
			"Подготовка презентации для защиты проекта",
			"Регистрация МИП и РИД",
		]
		
		let itAudit = {
			
			problem: [
				"Ограничения в условиях текущей̆ санкционной̆ политики",
				"Проблема логистики",
				"Завышенная стоимость оборудования и комплектующих",
				"Недоступность\nиностранного ПО",
			],
			
			solve: [
				"Аудит IT – инфраструктуры в сфере закупок и эксплуатации ИТ решений",
				"Аудит бюджета на IT инфраструктуру, выявление ненужных расходов и предложение мер по оптимизации затрат",
				"Аудит архитектуры системы с целью выявления проблемных моментов",
				"Выявление потенциальных рисков, связанных с инфраструктурой̆, и разработка плана минимизации этих рисков",
				"Проверка соответствия текущей инфраструктуры требованиям законодательства и стандартам отрасли",
			],
			
			gain: [
				"Снижение расходов на закупку оборудования и ПО",
				"Соответствие ИТ инфраструктуры современным требованиям, законодательству и Вашим потребностям",
			],
			
		}
		
		// === director
		
		let directorFeaturesRu = [
			i18next.t('доктор технических наук'),
			i18next.t('директор Института Искусственного интеллекта,\nробототехники и системной инженерии КФУ'),
			i18next.t('заведующий кафедрой робототехники и ИТС'),
			i18next.t('лауреат премии Завойского'),
			i18next.t('инженер\u00A0года\u00A0РФ'),
			i18next.t('действительный член Академии навигации\nи управления движением'),
		]
		
		// let directorFeaturesEn = [
		// 	'Ph.D., Prof.',
		// 	'Faculty Dean'
		// ]
		
		let directorDetails = {
			
			'ru-RU': [
				
				{
					head: i18next.t('Исследовательский опыт:'),
					body: i18next.t('исследователь по основным профилям деятельности и научный сотрудник с 18-летним стажем руководства научными группами, группами инженеров и производственными линиями;'),
				},
				
				{
					head: i18next.t('18 лет'),
					body: i18next.t('преподавательского стажа по специальности.'),
				},
				
				{
					head: i18next.t('Образование'),
					body: [
						i18next.t('Доктор технических наук по специальности 05.13.01 – Системный анализ, управление и обработка информации'),
						i18next.t('Профессор кафедры КАДТП ВМК КФУ'),
						i18next.t('Кандидат технических наук 05.12.13 – системы, сети и устройства телекоммуникаций'),
						i18next.t('Магистр техники и технологии по направлению «Телекоммуникации»'),
						i18next.t('Второе высшее образование «Преподаватель высшей школы»'),
						i18next.t('Действительный член Академии навигации и управления движением'),
						i18next.t('Лауреат национальной физической премии Завойского'),
						i18next.t('Лауреат национального конкурса «Инженер Года»')
					],
				},
				
				{
					head: i18next.t('Должности'),
					body: [
						i18next.t('Директор Института Искусственного интеллекта, робототехники и системной инженерии КФУ'),
						i18next.t('Заведующий кафедрой робототехники и ИТС'), 
						i18next.t('Руководитель стратегического проекта КФУ №4 (IT, ИИ и киберфизические системы)'),
						i18next.t('Советник по IT генерального директора КВЗ'),
					],
				},
				
				{
					head: i18next.t('Эксперт по проектному управлению: '),
					body: i18next.t('Осуществлено руководство, контроль исполнения и успешная сдача более 70 НИОКР и разработка более 100 изделий различного назначения; максимальная сумма договора – 150 млн. руб.; общая сумма договоров – более 1 млрд. рублей.'),
				},
				
				{
					head: i18next.t('Руководство проектными командами'),
					body: i18next.t('до 400 человек (по одному проекту). За все время работы не были сорваны сроки ни по одному из выполняемых проектов, независимо от сложности и требований заказчика.'),
				},
				
				{
					head: i18next.t('Специалист по бизнес-переговорам: '),
					body: i18next.t('проведение корпоративных переговоров (в том числе – жестких) с техническими, экономическими и проектными руководителями любых уровней.'),
				},
				
			],
			
			'en-GB': [
				
				{
					head: "Experienced project manager, engineer and scientist offering 19 years of success leading all phases of diverse technology projects",
					body: [
						'Faculty Dean – Artificial Intelligence, Robotics and System Engineering (AIRSI), Kazan Federal University',
						'Director of KFU Strategic Priority #4 (IT, AI and Robotics) – Priority 2030 Program',
						'Dr.Sc., Prof.',
						'M.Sc., M.Art. and Ph.D. credentials',
						'18-years complex system engineering and 18 years of computer programming',
						'18-years of teaching mastery in the fields of expertise. Laureate of National Zavoisky Physical Award. Laureate of National “Engineer of the Year” Prize.',
					],
				},
				
				{
					head: 'Business strategist;',
					body: 'plan and manage million-dollar projects (up to 5 million USD per project) aligning business goals with state-of-the art technology solutions.',
				},
				
				{
					head: 'Excellent communicator;',
					body: 'leverage technical, business and financial acumen to communicate effectively with client executives and their respective teams.',
				},
				
				{
					head: 'Expert in agile and waterfall project management methodologies.',
					body: 'Able to manage medium-to-large project teams (up to 400 men) and known for high-quality deliverables that meet or exceed timeline and budget targets.',
				},
				
			],
			
		}
		
		let directorSkills = {
			
			'ru-RU': [
				
				{
					head: i18next.t('Управление проектами и персоналом'),
					body: [
						i18next.t('Проектирование сложных технических систем'),
						i18next.t('Специалист по методикам проектного управления – классические (Водопад), гибкие (Agile, Scrum, XP)'),
						i18next.t('Руководитель проектов регионального и федерального уровня'),
					],
				},
				
				{
					head: i18next.t('Сферы исследований'),
					body: [
						i18next.t('Инерциальные, SLAM, маячковые и спутниковые навигационные системы, системы ADAS; системы машинного зрения'),
						i18next.t('Теория систем, системный анализ, проектное управление'),
						i18next.t('Беспроводные и сенсорные сети, антенные решетки с цифровой обработкой, СШП-радиолокация'),
						i18next.t('Цифровая обработка сигналов и статистический анализ немономодальных процессов'),
						i18next.t('Анализ акустических и вибрационных сигналов'),
						i18next.t('Машинное обучение, технологии BigData и DataMining'),
					],
				},
				
				{
					head: i18next.t('Сопровождение проектов'),
					body: [
						i18next.t('Анализ входящих требований, формирование ТЗ'),
						i18next.t('Техническая документация (в т.ч. в соответствии с военными ГОСТ)'),
						i18next.t('Точное планирование проектов по времени и исполнителями'),
						i18next.t('ОТК, техническое сопровождение, доработка, модернизация'),
					],
				},
				
				{
					head: i18next.t('Другие навыки'),
					body: [
						i18next.t('Существенные навыки в прямом, косвенном и матричном управлении коллективами'),
						i18next.t('Значительный опыт построения команд и обучения молодых сотрудников; планирование стратегий развития бизнесом'),
						i18next.t('Взаимодействие с партнерами (в т.ч. зарубежными – беглый устный английский; технический английский на уровне эксперта-носителя языка)'),
						i18next.t('Сверх-быстрое чтение (100+ страниц в час сложного технического текста на русском / английском)'),
						i18next.t('25 летний опыт научного программирования (MATLAB, MathCAD, Delphi, VHDL, ASM, C++)'),
					],
				},
				
			],
			
			'en-GB': [
				
				{
					head: i18next.t('Project Management:'),
					body: [
						i18next.t('Systems Engineering'),
						i18next.t('Enterprise and Governmentwide Implementations'),
						i18next.t('Project Scheduling'),
					],
				},
				
				{
					head: 'Research Acitivities:',
					body: [
						'Big Data, Data Mining Waterfall / Agile',
						'Wireless and Sensor Networking',
						'RTLS, SLAM, Inertial and Satellite Navigation Systems',
						'ADAS systems and vehicles automation',
						'Project Scheduling',
						'Radiolocation and Antenna Techniques',
						'Sound and Speech Analysis',
						'DSP and Stochastic Analysis',
					],
				},
				
				{
					head: 'IT Project Lifecycle:',
					body: [
						'Requirements Analysis',
						'Testing/QA/Rollout/Support',
						'Tech Writing',
						'Supervision',
					],
				},
				
				{
					head: 'Value-Added Leadership:',
					body: [
						'Cross-Functional',
						'Team Building & Mentoring',
						'Business & IT Planning Vendor Management',
						'Fluent Russian / English',
					],
				}
				
			],
			
		}
		
		let directorContacts = (
			<div className={`contacts director-contacts`}>
				<a className={`item addr-part-1`} href={`https://yandex.ru/maps/-/CDU0ZB9D`} target={'_blank'}>{i18next.t('Салиха Сайдашева 12, к.3')}</a>
				<span className={`item adde-part-2`}>{i18next.t('Казань, 420129')}</span>
				<a className={`item phone`} href={`tel:+7-917-27-27-100`}>+7-917-27-27-100</a>
				<a className={`item email`} href={`mailto:dmitry.kfu@ya.ru`}>dmitry.kfu@ya.ru</a>
				<a className={`item telegram`} href={`https://t.me/KindlyOwl`} target={'_blank'}>Telegram: @KindlyOwl</a>
			</div>
		)
		
		let directorCareerProgress = {
			
			'ru-RU': [
				i18next.t('НПО «Интеграл Плюс» (Казань): инженер беспроводных систем связи, 2006.'),
				i18next.t('ФГУП ФНПЦ им. В.И. Шимко «Радиоэлектроника»: начальник сектора беспроводных систем связи, отдел перспективных разработок, 2006-2008'),
				i18next.t('ООО КБ «Навигационные Технологии»: технический директор, первый зам. генерального – 2008-2014.'),
				i18next.t("КГТУ им. А.Н. Туполева (КАИ): ассистент, 2008-2012"),
				i18next.t('Казанский (Приволжский) Федеральный Университет: руководитель лекционных курсов по направлению «Телекоммуникации», старший преподаватель, 2012-2013.'),
				i18next.t('Казанский (Приволжский) Федеральный Университет: доцент, заведующий кафедрой «Автономные Робототехнические Системы», 2013-2014.'),
				i18next.t('Казанский (Приволжский) Федеральный Университет: проектный руководитель 5 OpenLab по направлению ИКТ – «Андромеда», «Ариадна», «Радуга», «Крона», «Маховик»'),
				i18next.t('Казанский (Приволжский) Федеральный Университет, доцент, зам. директора Высшей Школы ИТИС по науке и хоздоговорной деятельности, 2015.'),
				i18next.t('Казанский (Приволжский) Федеральный Университет, доцент каф. Радиофизики Института Физики, зам. заведующего отделением Радиофизики и информационных систем по хоздоговорной деятельности, ведущий научный сотрудник, 2015 – 2019.'),
				i18next.t('ПАО КАМАЗ - главный специалист НТЦ ПАО КАМАЗ по беспилотным системам, заместитель руководителя КБ беспилотных автомобилей, 2016 – 2020.'),
				i18next.t('Руководитель Центра исследований и разработок ИТС КАМАЗ-КФУ, 2018 – декабрь 2020 г.'),
				i18next.t('Заведующий кафедрой киберфизических систем КФУ, 2019 – декабрь 2021 г.'),
				i18next.t('Руководитель Центра Цифровых Трансформаций КФУ, 2020 – февраль 2021.'),
				i18next.t('Руководитель СП4 КФУ Приоритет-2030 (IT, ИИ и киберфизические технологии), 2021 – наст. время.'),
				i18next.t('Директор Института вычислительной математики и информационных систем (ИВМиИТ-ВМК) КФУ, 2020 – август 2023 г.'),
				i18next.t('Директор Института Искусственного интеллекта, робототехники и системной инженерии (ИИРСИ) КФУ, август 2023 – наст. время.'),
				i18next.t('Советник по IT генерального директора Казанского Вертолетного Завода, 2022 – наст. время.'),
			],
			
			'en-GB': [
				'R&D Center “Integral Plus” (Kazan), WIRELESS SYSTEMS ENGINEER, 2006',
				'R&D Center “RadioElectronix” n.a. Shimko (Kazan), SENIOR SYSTEM ENGINEER, 2006 to 200',
				'R&D Center “Navigation Technologies” (Kazan), CTO, 2008-201',
				'Kazan State Technical University n.a. Tupolev (Kazan), ASSISTANT, 2008-201',
				'Kazan Federal University, ITIS Higher Institute, SENIOR LECTURER IN TELECOMM. PROFILE, 2012-201',
				'Kazan Federal University, ITIS Higher Institute (Kazan), ASS. PROF., HEAD OF ROBOTICS DEPT., 2013 to 2014 Kazan Federal University, ITIS Higher Institute, DEPUTY HEAD OINSTITUTE IN SCIENCE, 2015',
				'Kazan Federal University, Institute of Physics, Division of Radiophysics and Information Systems, Radiophysics Department, ASS. PROF., DEPUTY HEAD OF DIVISION IN BUSINESS ACTIVITIES, 2016-2019',
				'KAMAZ R&D Center, DEPUTY HEAD OF STAFF IN VEHICLES AUTOMATION, 2016-2020',
				'KAMAZ-KFU R&D CENTER, HEAD OF STAFF, 2018-2020',
				'Head of KFU Institute of Physics Cyberphysics Dept., 2019-2021',
				'Head of KFU AI and neurotechnologies center for medicine, 2019-2021',
				'Head of KFU Digital Transformation Center, 2020-2022',
				'Director of Institute of Computational Mathematics and Information Technologies, KFU, 2020-2023',
				'Director of Institute of Artificial Intelligence, Robotics and System Engineering (AIRSI), KFU, August 2023 TILL NOW',
				'Director of KFU Strategic Priority #4 (IT, AI and robotics) – Priority 2030 Program, 2021 TILL NOW',
				'CEO Advisor on IT of Kazan Helicopters, 2022 TILL NOW',
			],
			
		}
		
		let directorMainProjects = {
			
			'ru-RU': [
				i18next.t('Разработка комплексной системы автопилотирования и обслуживающей инфраструктуры внутрилогистических и карьерных грузоперевозок – НИОКР «Голем» - заказчик ПАО КАМАЗ'),
				i18next.t('Гос. контракт на комплексную НИОКР «Разработка аппаратно-программных средств для создания Федеральной системы мониторинга критически важных и (или) потенциально опасных объектов инфраструктуры Российской Федерации и опасных грузов», шифр «Протокол» - ГИС-ориентированная АИС федерального уровня.'),
				i18next.t('Гос. контракт на комплексную НИОКР «Исследование возможностей и путей создания автоматизированных информационных систем по направлениям деятельности Управления по обеспечению деятельности подразделений специального назначения и авиации МВД России», шифр «Обследование УПСНиА» - ГИС-ориентированная АИС федерального уровня.'),
				i18next.t('Гос. контракт на разработку, поставку и сопровождение «Аппаратно-программный комплекс досмотрового оборудования «Ирбис-Д».'),
				i18next.t('Гос. контракт на разработку, поставку и сопровождение «Аппаратно-программный комплекс планирования и управления при проведении специальных операций и тактических учений «Ирбис-К».'),
			],
			
			'en-GB': [
				'Contract from KAMAZ PTZ on ADAS 4+ level system for truck vehicles, haul-dump trucks and intellectual infrastructure automation: “Golem”',
				'Government Contract on complex R&D: «Design of complex software platform for Russian-wide dedicated control and monitoring of critically dangerous and important cargo and all classified infrastructure objects movements» - federal wide GIS-oriented complex infrastructure software system',
				'Government Contract on complex R&D: «Design of complex software platform for Russian-wide monitoring and control of all types of special teams, compliant infrastructure objects and administration personnel» - federal wide GIS-oriented complex infrastructure software system',
				'Government Contract on custom engineering and further supplying: «Irbis-D – wearable complex of telescopic hybrid videosurveillance system for task forces»',
				'Government Contract on custom engineering and further supplying: «Irbis-K – special rugged command-and-control workstation and software framework for monitoring and control of tactical operations»',
			],
			
		}
		
		let directorSciProjects = [
			i18next.t('Проект «Андромеда» – Программное обеспечение виртуализации телекоммуникационной инфраструктуры – коллектив 7 человек, в сотрудничестве с ИППИ и ИПУ РАН.'),
			i18next.t('Проект «Радуга» - Мультиспектральный синтез и анализ изображений – коллектив 12 человек.'),
			i18next.t('Проект «Крона» - Разработка фрактальных антенн и антенных решеток фрактального типа – коллектив 10 человек, в сотрудничестве с Cisco Systems.'),
			i18next.t('Проект «Маховик» - кинетические накопители энергии супермаховичного типа – коллектив 7 человек.'),
			i18next.t('Проект «Ариадна» - инерциальные навигационные системы для платформ со сложной кинематикой движения – 8 человек, в сотрудничестве с ИППИ РАН и Royal Imperial College.'),
			i18next.t('Проект «Муромец» - обеспечение устойчивого двуногого прямохождения робототехнических платформ по сложным поверхностям – коллектив 12 человек.'),
			i18next.t('Проект «Альтиус-ФА» - фрактальные антенны и антенные системы для БПЛА – коллектив 10 человек.'),
			i18next.t('Проект «Мотылек» - система периметральной охраны на базе виртуальной антенной решетки из MEMS-акселерометров – коллектив 12 человек.'),
			i18next.t('Проект «Феникс» - система периметральной охраны на базе виброчувствительного кабеля с использованием принципов временной рефлектометрии – коллектив 20 человек.'),
			i18next.t('Проект «Одиссей» - разработка автоматизированного (уровня ADAS 4+) грузового транспортного средства для решения задач внутризаводской логистики – коллектив 70+ человек.'),
			i18next.t('Проект «Самсон» - разработка автоматизированного (уровня ADAS 4+) карьерного самосвала средней грузоподъемности – коллектив 40+ человек.'),
			i18next.t('Проект «Спутник» - разработка бортовой информационно-диагностической системы автомобилей КАМАЗ – коллектив 20+ человек.'),
			i18next.t('Проект «Эрго» - разработка ПО автоматизированного проектирования и изготовления антенных систем для радиостанций систем связи 4G+, 10 человек, в сотрудничестве с компанией Huawei.'),
			i18next.t('Проект «Лилия» - диктофон для мобильных платформ (iOS, Android) с шумоподавлением, комплексной речевой обработкой и решением задач распознавания речи (дважды выбрана редакцией AppStore, постоянно находится в Top1-Top3 Business русского AppStore). 15 человек.'),
			i18next.t('Проект «Школа Сов» - универсальное платформенное решение (три приложения) для изучения детьми русского\английского\немецкого языков с уникальным собственным движком распознавания нечеткой (детской, с дефектами) речи, логопедическим функционалом и автоматическим контролем выполнения уроков детьми (приложения регулярно находятся в топах Education и Kids русского AppStore). 25 человек.'),
			i18next.t('Проект «Четкость» - разработка радиостанций с обеспечением интеллектуального шумоподавления для нужд ТЭК и силовых структур, коллектив 18 человек.'),
			i18next.t('Проект «Авиценна» - разработка системы индивидуализированной экспертной диагностики состояния здоровья человека по данным KDL-анализов и автоматизированной обработкой анализов графических типов (рентгенография, УЗИ), коллектив 35 человек.'),
			i18next.t('Проект «Зеркало» - комплекс мобильных сервисов и портативных устройств индивидуализированной диагностики и постоянного состояния здоровья сотрудников предприятий в условиях повышенной экологической и-или эпидемиологической опасности, коллектив 30 человек.'),
			i18next.t('Проект «Studerus» - проект корректировки произношения студентов-иностранцев, коллектив 18 человек.'),
		]
		
		let directorChars = {
			
			'ru-RU': [
				{
					head: i18next.t('Ключевые характеристики как руководителя проектов'),
					body: [
						i18next.t('Создание и руководство сбалансированными командами в различных предметных областях, научных исследованиях и бизнес-инициативах. Как руководитель – сфокусирован на итоговых целях и непрерывном отслеживании прогресса работ для выполнения проектов точно в срок, запланированный бюджет и с требуемыми результатами'),
						i18next.t('Обеспечивает минимизацию технических, технологических и организационных рисков, исходя из текущего статуса по проекту и статистического анализа результатов аналогичных проектов в схожей предметной области. Специалист по экстремальному планированию и сопровождению процессов исполнения работ в условиях крайне сжатых сроков и изменяющихся требований заказчика.'),
						i18next.t('Технический специалист, обдающий достаточной квалификацией для выбора используемых инструментов и подходов по каждой задаче; всегда сам принимает участие (либо составляет) технические требования и техническое задания по проводящимся проектам. Поддерживает баланс между традиционным «Водопад» и экстремальным «Эджайл» проектированием в зависимости от внешних требований и требований к процессу выполнения проекта.'),
					],
				},
				
				{
					head: i18next.t('Ключевые характеристики как исследователя и инженера по предметным областям'),
					body: [
						i18next.t('Существенные темпы исследований в предметных областях: более 80 публикаций, патентов и РИД за последние 15 лет; 4 авторских курса по системному анализу и телекоммуникационным системам, ряд книг и пособий.'),
						i18next.t('Широкий перечень профессиональных компетенций: сверхбыстрое чтение на русском и английском языках (от 2000 до 10000 слов в минуту, в зависимости от типа текста); опыт программирования на MATLAB, VHDL, Delphi, C++, Assembler, MathCAD.'),
					],
				},
				
				{
					head: i18next.t('Ключевые характеристики как члена общества'),
					body: i18next.t('36 лет, 15 лет женат, имеет дочь, примерный семьянин. Больше всего ценит стабильность и уверенность в будущем для себя, своей семьи и своих коллег.'),
				},
			],
			
			'en-GB': [
				{
					head: i18next.t('Ключевые характеристики как руководителя проектов'),
					body: [
						i18next.t('Создание и руководство сбалансированными командами в различных предметных областях, научных исследованиях и бизнес-инициативах. Как руководитель – сфокусирован на итоговых целях и непрерывном отслеживании прогресса работ для выполнения проектов точно в срок, запланированный бюджет и с требуемыми результатами'),
						i18next.t('Обеспечивает минимизацию технических, технологических и организационных рисков, исходя из текущего статуса по проекту и статистического анализа результатов аналогичных проектов в схожей предметной области. Специалист по экстремальному планированию и сопровождению процессов исполнения работ в условиях крайне сжатых сроков и изменяющихся требований заказчика.'),
						i18next.t('Технический специалист, обдающий достаточной квалификацией для выбора используемых инструментов и подходов по каждой задаче; всегда сам принимает участие (либо составляет) технические требования и техническое задания по проводящимся проектам. Поддерживает баланс между традиционным «Водопад» и экстремальным «Эджайл» проектированием в зависимости от внешних требований и требований к процессу выполнения проекта.'),
					],
				},
				
				{
					head: i18next.t('Ключевые характеристики как исследователя и инженера по предметным областям'),
					body: [
						i18next.t('Strong background – official representative of Kazan Scientific Shool of unconventional RadioEngineering and Stochastic Analysis'),
						i18next.t('Wide range of interests with deep expertise and extensive real life project implementations – from indoor navigation systems to machine vision and swarm-based artificial intelligence'),
						i18next.t("Significant research progress – more than 80 journal publications and patents in the 2010-2019 years"),
						i18next.t("Wide range of specific skills – ultra-fast reading in English and Russian (about 2000 words per minute); programming experience in 10+ languages (MATLAB, VHDL, Delphi, Python, C/C++, Assembler, MathCAD, BASIC, LISP, FORTRAN etc)"),
					],
				},
				
				{
					head: i18next.t('Ключевые характеристики как члена общества'),
					body: i18next.t('36 лет, 15 лет женат, имеет дочь, примерный семьянин. Больше всего ценит стабильность и уверенность в будущем для себя, своей семьи и своих коллег.'),
				},
			],
			
		}
		
		let directorDetailsToggleBtn = (
			<div 
				className={`director-details-toggle-btn clickable details-${this.state.directorDetailsShown ? 'shown' : 'hidden'}`}
				onClick={(event) => {
					this.setState({
						directorDetailsShown: !this.state.directorDetailsShown,
					})
				}}
			>
				<span className={`text`}>
					{this.state.directorDetailsShown ? i18next.t("Свернуть") : i18next.t("Подробнее...")}
				</span>
				<img className={`icon arrow-down-icon`}/>
				<img className={`icon arrow-up-icon`}/>
			</div>
		)
		
		let directorEduEn = [
			'<b>B.Sc. in the field of Telecommunications</b>, Kazan State Technical University n.a. Tupolev (Kazan, RF), 2006',
			'M.Sc. in the field of Telecommunications, Kazan State Technical University n.a. Tupolev (Kazan, RF), 2008',
			'M.Art in the field of High School Teaching, Kazan State Technical University n.a. Tupolev (Kazan, RF), 2008',
			'Ph.D. in the field of Telecommunications, Kazan State Technical University n.a. Tupolev (Kazan, RF), 2013',
			'Doctor of Science Degree in the field of System Theory and Analysis, Kazan Federal University, 2022.',
		]
		
		Logger.groupEnd(logAllow)
		
		return (
			
			<div className="landing">

				<section className={'landing-header'}>
					
					<div className={'container'}>
						
						<h1 className="slogan">
							{i18next.t("Эксперты в области грантрайтинга, разработки и оптимизации закупок")}
						</h1>

						<h2 className="sub-slogan">
							{/* {i18next.t("Разработаем приложение, напишем заявку на участие в гранте,\nоптимизируем список закупок")} */}
							<div className={`item`}>{i18next.t('- Написание заявок на получение грантов')}</div>
							<div className={`item`}>{i18next.t('- Разработка приложений')}</div>
							<div className={`item`}>{i18next.t('- Оптимизация списка закупок (ИТ инфраструктура)')}</div>
						</h2>
						
						<div className="buttons">
							
							<div className={`row`}>
								
								<div className={`col-md-4 col1`}>
									<a
										// href={`mailto:${mainEmail}`}
										href={`#claim-grant`}
										className="btn btn-primary"
									>{i18next.t("Оформить заявку на грант")}</a>
								</div>
								
								<div className={`col-md-4 col2`}>
									<a
										// href={`mailto:${mainEmail}`}
										href={`#claim-dev`}
										className="btn btn-primary"
									>{i18next.t("Разработать приложение")}</a>
								</div>
								
								<div className={`col-md-4 col3`}>
									<a
										// href={`mailto:${mainEmail}`}
										href={`#claim-audit`}
										className="btn btn-primary"
									>{i18next.t("Оптимизировать закупки")}</a>
								</div>
								
							</div>
						
						</div>
						
					</div>
					
				</section>

				<section id="grants" className={'grants'}>
					
					<div className={'container'}>
						
						<h2 className={'section-header'}>
							{i18next.t("Гранты")}
						</h2>
						
						<div className={`section-body`}>
							
							{grantsItems.map((grantsItem, grantsItemIndex) => {
								return (
									<div className={`grants-item pre-line`} key={`grants-item-${grantsItemIndex}`}>
										{(Array.isArray(grantsItem)) ? (
											<>
												{grantsItem.map((grantsItemPart, grantsItemPartIndex) => {
													return (
														<div
															key={`grant-item-part-${grantsItemPartIndex}`}
															className={`grants-item-part pre-line`} 
														>{grantsItemPart}</div>
													)
												})}
											</>
										) : (
											<>
												{grantsItem}
											</>
										)}
									</div>
								)
							})}
							
						</div>
						
						{/* <div className={`laptop-img`}></div> */}
						
					</div>
					
				</section>
				
				<section id="grantwriting" className={'grantwriting'}>
					
					<div className={`container`}>
						
						<h2 className={'section-header'}>
							{i18next.t("Грантрайтинг")}
						</h2>
						
						<div className={`section-body`}>
							{i18next.t("Каждая вторая заявка получает финансовое обеспечение")}
						</div>
						
					</div>
					
				</section>
				
				<section id="claim-grant" className={`claim-grant`}>
					<div className={`claim-form-box`}>
						<ClaimForm
							preloader={this.props.preloader}
							model={new ClaimModel({serviceTypeAlias: ClaimModel.serviceTypeAliasGrant})}
							title={i18next.t("Оставить заявку\nна грант")}
						/>
					</div>
				</section>
				
				<section id="audit" className={'audit'}>
					
					<div className={`container`}>
						
						<h2 className={'section-header'}>
							{i18next.t("IT Аудит")}
						</h2>
						
						<div className={`row`}>
							
							{/* <div className={`col-xl-1 d-none d-lg-flex`}></div> */}
							
							<div className={`col-xl-4 col-md-4 problem-col`}>
								
								<div className={`problem items card`}>
								
									<div className={`card-body`}>
										
										<div className={`header`}>
											<img className={`icon`}/>
											<h3 className={`title`}>{i18next.t("Проблема")}</h3>
										</div>
										
										{itAudit.problem.map((item, index) => {
											return (
												<div className={`item`} key={`item-${index}`}>
													{item}
												</div>
											)
										})}    
										
									</div>
									
								</div>
								
							</div>
							
							<div className={`col-xl-4 col-md-4 solve-col`}>
								
								<div className={`solve items card`}>
									
									<div className={`card-body`}>
										
										<div className={`header`}>
											<img className={`icon`}/>
											<h3 className={`title`}>{i18next.t("Предлагаемое решение")}</h3>                                        
										</div>
										
										{itAudit.solve.map((item, index) => {
											return (
												<div className={`item`} key={`item-${index}`}>
													{item}
												</div>
											)
										})}    
										
									</div>
									
								</div>
								
							</div>
							
							<div className={`col-xl-4 col-md-4 gain-col`}>
								
								<div className={`gain items card`}>

									<div className={`card-body`}>
										
										<div className={`header`}>
											<img className={`icon`} />
											<h3 className='title'>{i18next.t("Ваша выгода")}</h3>
										</div>
									
										{itAudit.gain.map((item, index) => {
											return (
												<div className={`item`} key={`item-${index}`}>
													{item}
												</div>
											)
										})}
										
									</div>
									
								</div>
								
							</div>
							
						</div>
						
					</div>
					
				</section>
				
				<section id="audit-success" className={'audit-success'}>
					
					<div className={`container`}>
						
						<h2 className={`section-header`}>
							{i18next.t("Успехи IT Аудита")}
						</h2>
						
						<div className={`row`}>
							
							<div className={`col-md-4`}>
								<div className={`item card`}>
									<div className={`card-body`}>
										<h3 className={`title`}>{i18next.t("Казанский\nвертолетный\nзавод")}</h3>
										<p>
											<b>{i18next.t("Экономия ~ 40 млн. руб.")}</b>
											<br/>{i18next.t("при закупке рабочих станций, ПО и оргтехники")}
										</p>
										<p>
											{i18next.t("При объёме закупки")}
											<br/><b>{i18next.t("300 млн. руб.")}</b>
										</p>
									</div>
								</div>
							</div>
							
							<div className={`col-md-4`}>
								<div className={`item card`}>
									<div className={`card-body`}>
										<h3 className={'title'}>ПАО КамАЗ</h3>
										<p>Испытательные стенды, ПО и услуги сторонних контрагентов, в рамках прорывной инженерной школы</p>
										<p><b>Экономия ~ 80 млн. руб.</b></p>
										<p>
											При объёме закупок
											<br/><b>более 300 млн. руб.</b>
										</p>
									</div>
								</div>
							</div>
							
							<div className={`col-md-4`}>
								<div className={`item card`}>
									<div className={`card-body`}>
										<h3 className={`title`}>
											Радиоэлектроника
											<br/>им. В.И. Шимко
										</h3>
										<p>
											<b>Экономия ~ 150 млн. руб.</b>
											<br/>на контрольно-измирительной аппаратуре
										</p>
										<p>
											Закупка более
											<br/><b>500 млн. руб.</b>
										</p>
									</div>
								</div>
							</div>
							
						</div>
						
					</div>
					
				</section>
				
				<section id="claim-audit" className={`claim-audit`}>
					<div className={`claim-form-box`}>
						<ClaimForm
							preloader={this.props.preloader}
							model={new ClaimModel({serviceTypeAlias: ClaimModel.serviceTypeAliasAudit})}
							title={i18next.t("Оставить заявку\nна аудит")}
						/>
					</div>
				</section>
				
				<section id="dev" className={`dev`}>
					
					<div className={`container`}>
						
						<h2 className={`section-header`}>
							{i18next.t("Разработка")}
						</h2>
						
						<div className={`graph-icon-box`}>
							<img className={`graph-icon`}/>
						</div>
						
						<h4 className={`market-situation-header`}>
							Текущая ситуация на рынке для отечественного бизнеса:
						</h4>
						
						<div className={`market-situation-list`}>
							
							<div className={`item`}>
								&bull; &laquo;Выбывание&raquo; иностранных решений из российского ИТ-ландшафта
							</div>
							
							<div className={`item`}>
								&bull; Необходимость оптимизации текущих и восстановления выбывших бизнес-процессов
							</div>
							
							<div className={`item`}>
								&bull; Высокая стоимость и длительные сроки разработки сложных программных решений
							</div>
							
							<div className={`item`}>
								&bull; Средний̆ срок реализации простых бизнес-кейсов: 4-6 месяцев
							</div>
							
						</div>
						
						<div className={`your-result`}>
						
							<div className={`prize-icon-box`}>
								<img className={`prize-icon`}/>
							</div>
							
							<h4 className={`title`}>Ваш результат:</h4>
							
							<div className={`items`}>
								
								<div className={`hr d-none d-md-block`}></div>
								
								<div className={`row`}>
									
									<div className={`item-col col-md-3 item-col`}>
										
										<div className={`item`}>
											
											<div className={`icon`}>1</div>
											
											<div className={`text`}>
												Разработка дизайна
												<br/>«под ключ» под Вашу
												<br/>айдентику и брендбук
											</div>
											
										</div>
										
									</div>
									
									<div className={`item-col col-md-3 item-col`}>
										
										<div className={`item`}>
											
											<div className={`icon`}>2</div>
											
											<div className={`text`}>
												Уточнение и формализация программируемого
												<br/>бизнес-процесса, определение
												<br/>его четкого функционала
												<br/>и критериев выполнения
											</div>
											
										</div>
										
									</div>
									
									<div className={`item-col col-md-3 item-col`}>
										
										<div className={`item`}>
											
											<div className={`icon`}>3</div>
											
											<div className={`text`}>
												
												Определение платформ для разработки:
												iOS, Android, Windows, Linux, MacOS, Web
												
												{/* <br/>(iOS, Android, Windows, Linux) */}
												
												{/* <br/>(iOS, Android, Windows, Linux, Web) */}
												
												{/* 
												<div className={`platform-list`}>
													<br/>&bull; iOS
													<br/>&bull; Android
													<br/>&bull; Windows
													<br/>&bull; Linux
													<br/>&bull; MacOS
													<br/>&bull; Web
												</div>
												*/}
												
											</div>
											
										</div>
										
									</div>
									
									<div className={`item-col col-md-3 item-col`}>
										
										<div className={`item`}>
											
											<div className={`icon`}>4</div>
											
											<div className={`text`}>
												Средний̆ срок реализации проекта:<br/>2-3 месяца
											</div>
											
										</div>
										
									</div>
									
								</div>
								
							</div>
							
						</div>
						
					</div>
					
				</section>
				
				<section id="samples" className={`dev-samples`}>
					
					<div className={`container`}>
						
						<h2 className={`section-header`}>
							{i18next.t("Разработанные нами приложения")}
						</h2>
						
						<div className={`items`}>
							
							<div className={`row`}>
								
								<div className={`item-col col-md-6 col-xl-4`}>
									<LandingDevSample
										alias={`amalthea-m`}
										title={i18next.t("Амальтея-М")}
										text={`Комплекс цифровых решений для биомедицинского, промышленного и транспортного мониторинга`}
									/>
								</div>
								
								<div className={`item-col col-md-6 col-xl-4`}>
									<LandingDevSample
										alias={`owl-school`}
										title={i18next.t("Школа Сов – КФУ")}
										text={`Серия языковых обучающих приложений для детей`}
										appleAppStoreUrl={`https://apps.apple.com/ru/app/%D1%88%D0%BA%D0%BE%D0%BB%D0%B0-%D1%81%D0%BE%D0%B2-%D0%BA%D1%84%D1%83-pro/id1605943825`}
									/>
								</div>
								
								<div className={`item-col col-md-6 col-xl-4`}>
									<LandingDevSample
										alias={`asdik`}
										title={i18next.t("АСДИК")}
										text={`Комплекс постобработки и поддержки принятия решения для средств УЗИ-диагностики`}
									/>
								</div>
								
								<div className={`item-col col-md-6 col-xl-4`}>
									<LandingDevSample
										alias={`studerus`}
										title={i18next.t("StudeRus")}
										text={`Обучающие мобильные приложения для отработки произносительных навыков иностранных учащихся`}
										appleAppStoreUrl={`https://apps.apple.com/ru/app/studerus-pro/id1605943000`}
									/>
								</div>
								
								<div className={`item-col col-md-6 col-xl-4`}>
									<LandingDevSample
										alias={`lily`}
										title={i18next.t("Лилия")}
										text={`Умный диктофон с функцией шумоподавления`}
										appleAppStoreUrl={`https://apps.apple.com/ru/app/%D1%83%D0%BC%D0%BD%D1%8B%D0%B9-%D0%B4%D0%B8%D0%BA%D1%82%D0%BE%D1%84%D0%BE%D0%BD-%D0%BB%D0%B8%D0%BB%D0%B8%D1%8F/id1439443246`}
									/>
								</div>
								
								<div className={`item-col col-md-6 col-xl-4`}>
									<LandingDevSample
										alias={`owl-paint`}
										title={`OwlPaint`}
										text={`Фоторедактор`}
									/>
								</div>
								
								<div className={`col-md-3 col-xl-4`}></div>
								
								<div className={`item-col col-md-6 col-xl-4`}>
									<LandingDevSample
										alias={`sputnik`}
										title={`Спутник`}
										text={`Оболочка бортового компьютера автомобилей КАМАЗ серии K5`}
									/>
								</div>
									
							</div>
							
						</div>
						
					</div>
					
				</section>
				
				<section id="claim-dev" className={`claim-dev`}>
					<div className={`claim-form-box`}>
						<ClaimForm
							preloader={this.props.preloader}
							model={new ClaimModel({serviceTypeAlias: ClaimModel.serviceTypeAliasDev})}
							title={i18next.t("Оставить заявку\nна разработку")}
						/>
					</div>
				</section>
				
				<section id="director" className={`director`}>
					
					<div className={`container`}>
						
						<div className={`head`}>
							
							<h2 className={`section-header`}>
								
								{i18next.t("Сведения о руководителе")}
								
								{(!!this.state.useLangPicker) && (
									<LangPicker
										langOnCLick={this.setLang}
									/>
								)}
								
								{/* <div className={`lang-picker`}>
									
									<img
										className={`lang ${i18next.language == 'ru-RU' ? 'lang-active' : ''} lang-ru clickable`}
										onClick={() => { 
											this.setLang('ru-RU')
										}}
									/>
									 
									 <img
										className={`lang ${i18next.language == 'en-GB'? 'lang-active' : ''} lang-en clickable`}
										onClick={() => { 
											this.setLang('en-GB')
										}}
									/>
									
								</div> */}
								
							</h2>
							
							{/*
							<div className={`info`}>
								<span className={`name`}>Чикрин Дмитрий</span>, 
								{directorFeatures.join(', ')}
							</div>
							*/}
							
							{(this.state.useDirectorsPhoto == 1) ? (
								
								<div className={`info`}>
								
									<div className={`name`}>
										{i18next.t('Дмитрий Чикрин')}
									</div>
									
									<div className={`row`}>
										
										<div className={`col-sm-5 img-col`}>
											<img className={`dmitrij-img`}/>
										</div>
										
										<div className={`col-sm-7 text-col`}>
											
											<ul className={`features`}>
											
												{directorFeaturesRu.map((x, i) => {
													return (
														<li className={`item`} key={`item-${i}`}>
															{x}
														</li>
													)
												})}
												
											</ul>
											
										</div>
										
									</div>
									
								</div>
								
							) : (
								
								<div className={`info text-center`}>
									<span className={`name`}>{i18next.t('Дмитрий Чикрин')}</span>
									{/* <br/>{directorFeatures.join(' | ')} */}
									<br/>{directorFeaturesRu.filter(x => x != '').join(' | ')}
								</div>
								
							)}
							
							{directorContacts}
							
							<div className={`director-details-toggle-btn-box`}>
								{directorDetailsToggleBtn}
							</div>
							
						</div>
						
					</div>
					
				</section>
				
				{(!!this.state.directorDetailsShown) && (
					
					<>
					
						<section className={`director-details`}>
						
							<div className={`container`}>
								
								<div className={`director-details-item main`}>
									
									<p className={`bigger-text bold pre-line`}>
										{i18next.t('Директор Института, руководитель проектных групп,\nсистемный архитектор, евангелист беспилотной техники')}
									</p>
									
									<br/>
									
									<ul className={`bold`}>
										<li>{i18next.t("разработка программного / аппаратного обеспечения")}</li>
										<li>{i18next.t("исследования по профилю")}</li>
										<li>{i18next.t("построение сложных инфраструктурных решений и технических систем")}</li>
									</ul>
									
									{directorDetails[this.state.lang].map((x, i) => {
										return (
											<div className={`smaller-text`} key={`director-details-${i}`}>
												
												<b className={`head bigger-text`}>{x.head}</b>&nbsp;
												
												{(Array.isArray(x.body)) ? (
													<ul>
														{x.body.map((boodyItem, boodyItemIndex) => {
															return (
																<li key={`item-${boodyItemIndex}`}>{boodyItem}</li>
															)
														})}
													</ul>
												) : (
													<>{x.body}</>
												)}
												
											</div>
										)
									})}
									
								</div>
								
								<div className={`director-details-item skills`}>
									
									<h3 className={`head`}>
										{i18next.t("Основные навыки")}
									</h3>
									
									<div className={`body`}>
										
										<div className={`row`}>
											
											{directorSkills[this.state.lang].map((x, i) => {
												
												return (
													
													<div
														key={`director-skills-item-${i}`}
														className={[
															'skill-box',
															// 'col-md-6', 
															// 'col-xl-3',
															'col-12',
														].join(' ')}
													>
														
														<div className={`skill director-skill`}>
														
															<div className={`head bold skill-name`}>
																{x.head}
															</div>
															
															<div className={`body smaller-text`}>
																{(Array.isArray(x.body)) ? (
																	<>
																		{x.body.map((boodyItem, boodyItemIndex) => {
																			return (
																				<div 
																					key={`skill-item-${boodyItemIndex}`}
																					className={`skill-item`}
																				>&bull; {boodyItem}</div>
																			)
																		})}
																		{/* <ul>
																			{x.body.map(boodyItem => {
																				return (
																					<li>{boodyItem}</li>
																				)
																			})}
																		</ul> */}
																	</>
																) : (
																	<>{x.body}</>
																)}
															</div>
															
														</div>
														
													</div>
													
												)
												
											})}
											
										</div>
										
									</div>
									
								</div>
								
								<div className={`director-details-item career`}>
									
									<h3 className={`head`}>
										{i18next.t("Производственная и исследовательская карьера")}
									</h3>
									
									<div className={`body`}>
										
										{directorCareerProgress[this.state.lang].map((careerItem, careerItemIndex) => {
											
											return (
												
												<div className={`item career-item`} key={`director-career-item-${careerItemIndex}`}>
													&bull; {careerItem}
												</div>
												
											)
											
										})}
										
									</div>
									
								</div>
								
								<div className={`director-details-item main-projects`}>
									
									<h3 className={`head`}>
										{i18next.t("Наиболее значимые проекты (с 2008 по 2023)")}
									</h3>
									
									<p className={`bold pre-line`}>
										{i18next.t("Руководитель проектов федерального уровня (все были приняты\nЗаказчиком без замечаний и сдвига сроков исполнения)")}:
									</p>
									
									<br/>
									
									<ol>
										{directorMainProjects[this.state.lang].map((x, i) => {
											return (
												<li key={`li-${i}`}>{x}</li>
											)
										})}
									</ol>
									
								</div>
								
								<div className={`director-details-item sci-projects`}>
									
									<h3 className={`head`}>
										{i18next.t("Наиболее наукоемкие проекты (с 2014 по 2023)")}
									</h3>
									
									<ol>
										{directorSciProjects.map((x, i)=> {
											return (
												<li key={`li-${i}`}>{x}</li>
											)
										})}
									</ol>
									
								</div>
								
								<div className={`director-details-item chars`}>
									
									<h3 className={`head`}>
										{i18next.t("Ключевые характеристики")}
									</h3>
									
									<div className={`body`}>
										
										{directorChars[this.state.lang].map((charsGroup, charsGroupIndex) => {
											return (
												<div className={`chars-group`} key={`director-chars-group-${charsGroupIndex}`}>
													
													<div className={`chars-group-name bold`}>
														{charsGroup.head}
													</div>
													
													{(Array.isArray(charsGroup.body)) ? (
														<ul className={`chars-group-content`}>
															{charsGroup.body.map((boodyItem, i) => {
																return (
																	<li key={i}>{boodyItem}</li>
																)
															})}
														</ul>
													) : (
														<div className={`chars-group-content`}>
															{charsGroup.body}
														</div>
													)}
													
												</div>
											)
										})}
										
									</div>
									
								</div>
								
								{(this.state.lang == 'en-GB') && (
									
									<div className={`director-details-item edu-en`}>
									
										<h3 className={`head`}>
											{i18next.t("Education & Credentials")}
										</h3>
										
										{/* {(this.state.lang == 'en-GB') && (
											
											<>
												{directorEduEn.map((x) => {
													return (
														<div className={`item`}>
															{x}
														</div>
													)
												})}
											</>
											
										)} */}
										
										<div className={`body`}>
											<b>B.Sc. in the field of Telecommunications</b>, Kazan State Technical University n.a. Tupolev (Kazan, RF), 2006
											<br/><b>M.Sc. in the field of Telecommunications</b>, Kazan State Technical University n.a. Tupolev (Kazan, RF), 2008
											<br/><b>M.Art in the field of High School Teaching</b>, Kazan State Technical University n.a. Tupolev (Kazan, RF), 2008
											<br/><b>Ph.D. in the field of Telecommunications</b>, Kazan State Technical University n.a. Tupolev (Kazan, RF), 2013
											<br/><b>Doctor of Science Degree in the field of System Theory and Analysis</b>, Kazan Federal University, 2022.
										</div>
										
									</div>
									
								)}
								
								{directorContacts}
								
								<div className={`director-details-toggle-btn-box`}>
									{directorDetailsToggleBtn}
								</div>
								
							</div>
							
						</section>
						
					</>
					
				)}
				
				<section id="contacts" className={`contacts`}>
					
					<h2 className={`section-header`}>
						{i18next.t("Контакты")}
					</h2>
					
					<div className={`container`}>
						
						<div className={`row`}>
							
							<div className={`col-md-1 col-xl-3`}></div>
							
							<div className={`item-col col-md-5 col-xl-3 kruchinin-contacts-col`}>
								<LandingContactsItem
									name={i18next.t("Дмитрий Кручинин")}
									phone={'+7 952 031-20-37'}
									telegram={'DmitryKruch'}
									// email={'dmitrykruch@gmail.com'}
									// email={Config.kruchininNativeEmail}
									// emailName={Config.kruchininNativeEmailName}
									emails={[
										'dmitrykruch@gmail.com',
										// [Config.kruchininNativeEmail, Config.kruchininNativeEmailName],
									]}
								/>
							</div>
							
							<div className={`item-col col-md-5 col-xl-3 semenov-contacts-col`}>
								<LandingContactsItem
									name={i18next.t("Антон Семенов")}
									phone={'+7 996 403-32-32'}
									telegram={'S_Anton_Ufa'}
									// email={'semenov-ufa1@yandex.ru'}
									// email={Config.semenovNativeEmail}
									// emailName={Config.semenovNativeEmailName}
									emails={[
										'semenov-ufa1@yandex.ru',
										// [Config.semenovNativeEmail, Config.semenovNativeEmailName],
									]}
								/>
							</div>
							
						</div>
						
					</div>
					
				</section>

			</div>
			
		)
		
	}
	
}

Landing.propTypes = {
	preloader: PropTypes.object,
}

Landing.defaultProps = {
	preloader: {},
}

export default Landing