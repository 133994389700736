import Utils from "./Utils";

export default class Config {
	
	static backendApiKey = 'vA9PmksxzqAFTe6';
	
	static version = '1.0';
	static version_datetime = '2023-08-27 23:39';
	
	static projectName = 'Грантрайтинг, разработка приложений и оптимизация закупок';
	
	static prodFrontendHost = 'xn--80aahcyf0advcvd.xn--p1ai'; // грантрайтинг.рф
	static prodFrontendHostRu = 'грантрайтинг.рф';
	
	static semenovNativeEmail = 'semenov@' + this.prodFrontendHost;
	static semenovNativeEmailRu = 'semenov@' + this.prodFrontendHostRu;
	
	static kruchininNativeEmail = 'kruchinin@' + this.prodFrontendHost;
	static kruchininNativeEmailRu = 'kruchinin@' + this.prodFrontendHostRu;
	
	static prodBackendUrl = 'https://core.xn--80aahcyf0advcvd.xn--p1ai';
	static devBackendUrl = 'https://core-dev-wbwuz9u38u.xn--80aahcyf0advcvd.xn--p1ai';

	static defaultLang = 'ru-RU';
	// static defaultLang = 'en-GB';
	// static defaultLang = navigator.language;
	
	static tinyMceMainConfig = {
		// language: i18next.language,
		height: 500,
		statusbar: true,
		menubar: true,
		plugins: [
			'advlist',
			'autolink',
			'lists',
			'link',
			'image',
			'charmap',
			'print',
			'preview',
			'anchor',
			'searchreplace',
			'visualblocks',
			'code',
			'fullscreen',
			'insertdatetime',
			'media',
			'table',
			'paste',
			'table',
			'charmap',
			'code',
			// 'codesample',
			'fullscreen',
			// 'formula',
			// 'formula2',
		],
		toolbar1: [
			'undo',
			'redo',
			'styles',
			'fontsize',
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'forecolor',
			'backcolor',
			'lineheight',
			'bullist',
			'numlist',
			'align',
			'outdent',
			'indent',
			'removeformat',
			'table',
			'image',
			'media',
			'charmap',
			// 'paste',
			'code',
			'fullscreen',
			'codesample',
		].join(' '),
	};
	
	static tinyMceModalConfig = {
		// language: i18next.language,
		height: 500,
		menubar: true,
		statusbar: true,
		plugins: [
			'advlist',
			'autolink',
			'lists',
			'link',
			'image',
			'charmap',
			'print',
			'preview',
			'anchor',
			'searchreplace',
			'visualblocks',
			'code',
			'insertdatetime',
			'media',
			'table',
			'paste',
			'table',
			'charmap',
			'code',
			'codesample',
			'fullscreen',
			// 'formula',
		],
		toolbar: [
			'undo',
			'redo',
			'styles',
			'fontsize',
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'forecolor',
			'backcolor',
			'lineheight',
			'bullist',
			'numlist',
			'align',
			'outdent',
			'indent',
			'removeformat',
			'table',
			// 'image',
			// 'media',
			// 'charmap',
			'code',
			'fullscreen',
			// 'codesample',
			// 'paste',
			// 'formula',
		].join(' '),
	};
	
	static allowFormFake() {
		return Utils.isLoc();
	}
	
	static apiUrl = (path, apiVersion = 1) => {
		return Config.backendUrl + '/api/v' + apiVersion + '/' + path;
	};
	
	static acceptedFiles = [
		
		{
			name: 'PDF',
			mime: 'application/pdf',
			ext: 'pdf'
		},
		
		{
			name: 'Microsoft Excel',
			mime: 'application/vnd.ms-excel',
			ext: 'xls'
		},
		
		{
			name: 'Microsoft Excel',
			mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			ext: 'xlsx'
		},
		
		{
			name: 'Microsoft Word',
			mime: 'application/msword',
			ext: 'doc'
		},
		
		{
			name: 'Microsoft Word',
			mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			ext: 'docx'
		},
		
		{
			name: 'Microsoft PowerPoint',
			mime: 'application/vnd.ms-powerpoint',
			ext: 'ppt'
		},
		
		{
			name: 'Microsoft PowerPoint',
			mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
			ext: 'pptx'
		},
		
		{
			name: 'OpenDocument',
			mime: 'application/vnd.oasis.opendocument.text',
			ext: 'odt'
		},
		
		// {
		//     name: 'Zip',
		//     mime: 'application/zip',
		//     ext: 'zip'
		// },
		
		// {
		//     name: '7z',
		//     mime: 'application/x-7z-compressed',
		//     ext: '7z'
		// },
	
	];
	
	static getAcceptedFilesMimes = () => {
		return this.acceptedFiles.map(data => data.mime).join(', ');
	};
	
	static getAcceptedFilesSummaryArray = () => {
		return this.acceptedFiles.map(data => {
			return data.name + ' (' + data.ext + ')';
		});
	};
	
	static permissions = {};
	
	static contacts = [
		
		{
			alias: 'kruchinin',
			
			name: 'Дмитрий Кручинин',
			email: 'dmitrykruch@gmail.com',
			// email: this.kruchininNativeEmailRu,
			phone:  '+7 952 031-20-37',

			showInTopMenu: 1,
			showInFooter: 1,
		},
		
		{
			alias: 'semenov',
			
			name: 'Антон Семенов',
			email: 'semenov-ufa1@yandex.ru',
			// email: this.semenovNativeEmailRu,
			phone:  '+7 996 403-32-32',
			
			showInTopMenu: 1,
			showInFooter: 1,
		},
		
	]
	
}