import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import Utils from '../helpers/Utils'
import ClaimModel from '../models/ClaimModel'
import Logger from '../helpers/Logger'
import Config from '../helpers/Config'
import axios from 'axios'

class ClaimForm extends React.Component {

	constructor(props) {
		
		super(props)
		
		const model = props.model ? {...props.model} : new ClaimModel()
		
		this.state = {
			model: model,
		}
		
		if (Utils.allowFormFake()) {
			
			if (this.props.useContactNameField) {
				model.contactName = '*** TEST ***'				
			}
			
			model.phone = '89876543210'
			model.email = 'test.test@test.test'
			
		}
		
	}
	
	isFilled = () => {
		
		const logName = 'ClaimForm.isFilled'
		const logAllow = 0
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		let model = this.state.model
		
		let phone = model.phone
		let phoneIsValid = phone ? Utils.checkPhone(phone) : false
		// let phoneIsValid = phone
		Logger.log(phone, 'phone', logAllow)
		
		let email = model.email
		let emailIsValid = email ? Utils.checkEmail(email) : false
		// let emailIsValid = email
		Logger.log(email, 'email', logAllow)
		
		Logger.groupEnd(logAllow)
		
		return phoneIsValid || emailIsValid
		
	}
	
	inputChange = (event) => {
		
		const model = this.state.model;
		
		const field = event.target;
		const value = field.type === 'checkbox' ? field.checked : field.value;
		const attr = field.getAttribute('name');
		
		model[attr] = value;
		
		this.setState({
			model: model,
		})
		
	}
	
	submit = (event) => {
		
		event.preventDefault()
		
		const form = event.currentTarget
		const model = this.state.model
		
		if (form.checkValidity()) {
			
			const isFilled = this.isFilled()
			
			if (this.props.preloader.show) {
				this.props.preloader.show()
			}
			
			if (isFilled) {
				
				axios({
					method: 'post',
					url: Utils.backendUrl(`api/claim`),
					data: model,
					params: {
						'apiKey': Config.backendApiKey,
					},
				}).then((response) => {
					
					const logName = 'ClaimForm.submit.ajax.done'
					const logAllow = 1
					const logCollapse = 0
					
					Logger.groupStart(logName, logAllow, logCollapse)
					
					if (this.props.preloader.hide) {
						this.props.preloader.hide()
					}
					
					// Logger.log(response, 'response', logAllow)
					Logger.log(response.data, 'response.data', logAllow)
					
					if (response.data == 1) {
						window.alert(i18next.t("Спасибо! Заявка успешно отправлена"))
					} else {
						window.alert(i18next.t("Извините! На свервере неполадки. Пожалуйста, обратитесь по указанным конткантам"))
					}
					
					Logger.groupEnd(logAllow)
					
				}).catch((axiosError) => {
					
					console.log(`axiosError = %o`, axiosError)
					
				})
				
			} else {
				
				window.alert(i18next.t("Пожалуйста, укажите корректный номер телефона и / или адрес электронной почты"))
				
			}
			
		}
		
	}
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		const logName = 'ClaimForm.render'
		const logAllow = 0
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		let isFilled = this.isFilled()
		// let isFilled = false
		Logger.log(isFilled, 'isFilled', logAllow)
		
		const model = this.state.model
		Logger.log(model, 'model', logAllow)
		
		Logger.groupEnd(logAllow)
		
		return (
			
			<form 
				className={`ClaimForm claim-form ${isFilled ? 'filled' : 'not-filled'}`}
				onSubmit={(event) => {
					this.submit(event)
				}}
			>
				
				{(this.props.title) && (
					<div className={`h1 pre-line-forced`}>
						{this.props.title}
					</div>
				)}
				
				{(this.props.useContactNameField) && (
					<div className={`form-group contactName`}>
						<label>{i18next.t("Ваше имя")}</label>
						<input
							name={`contactName`}
							type={'text'}
							className={`form-control`}
							value={model.contactName}
							onChange={this.inputChange}
						/>
					</div>
				)}
				
				<div className={`form-group phone`}>
					<label>{i18next.t("Телефон")}</label>
					<input
						name={`phone`}
						type={'text'}
						className={`form-control`}
						value={model.phone}
						onChange={this.inputChange}
					/>
				</div>
				
				<div className={`form-text or-text`}>
					{i18next.t("и / или")}
				</div>
				
				<div className={`form-group email`}>
					<label>{i18next.t("E-mail")}</label>
					<input
						name={`email`}
						type={'email'}
						className={`form-control`}
						value={model.email}
						onChange={this.inputChange}
					/>
				</div>
				
				<div className={`controls form-controls`}>
					<button 
						type={'submit'} 
						className={`btn btn-primary send-btn submit-btn`}
						disabled={!isFilled}
					>{i18next.t("Отправить")}</button>
				</div>
				
			</form>
			
		)
		
	}
	
}

ClaimForm.propTypes = {
	preloader: PropTypes.object,
	model: PropTypes.instanceOf(ClaimModel),
	title: PropTypes.string,
	useContactNameField: PropTypes.bool,
}

ClaimForm.defaultProps = {
	preloader: {},
	useContactNameField: true,
}

export default ClaimForm