import i18next from 'i18next'
// import './i18n';

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import React from 'react'
import TopMenu from './components/TopMenu'
import BootstrapBreakpointIndicator from './components/helpers/BootstrapBreakpointIndicator/BootstrapBreakpointIndicator'
import Landing from './components/Landing'
import Utils from './helpers/Utils'
import Preloader from './components/helpers/Preloader/Preloader'

class App extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			preloaderIsShown: false,
		}
	}
	
	preloader = {
		show: () => {
			this.setState((prevState) => {
				return {
					preloaderIsShown: true,
				};
			});
		},
		hide: () => {
			this.setState((prevState) => {
				return {
					preloaderIsShown: false,
				};
			});
		},
	};
	
	componentDidMount() {
		
		// console.log(`window.location.hostname [` + typeof window.location.hostname + `] = %o`, window.location.hostname)
		
		Utils.setTitle()
		
		// console.log(`i18next.language [` + typeof i18next.language + `] = %o`, i18next.language)
		
	}

	render() {
		
		return (
			
			<div className={'App'}>
				
				<header>
					<TopMenu/>
				</header>
				
				<div className={'page'}>
					<Landing
						preloader={this.preloader}
					/>
				</div>
				
				{(Utils.isDev()) && (
					<BootstrapBreakpointIndicator/>
				)}
				
				<Preloader show={this.state.preloaderIsShown}/>
				
			</div>
			
		)
		
	}
	
}

export default App
